<template>
	<v-card id="chat-box" elevation="24">
		<v-container fill-height fixed>
			<v-layout column>
				<v-flex shrink>
					<v-list dense nav class="py-0">
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-title>Тех. поддержка</v-list-item-title>
								<v-list-item-subtitle>Спрашивайте, не стесняйтесь</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-icon>
								<v-btn icon @click="close">
									<v-icon>icon-close</v-icon>
								</v-btn>
							</v-list-item-icon>
						</v-list-item>
					</v-list>
					<v-divider />
				</v-flex>
				<v-flex grow class="chat">
					<div>
						<ol v-if="messages">
							<li
								v-for="(message, id) in messages"
								:key="id"
								:class="message.sender == 'self' ? 'visitor' : 'admin'"
							>
								<div class="msg">
									<p v-html="message.message" />
									<div class="time" v-text="message.date" />
								</div>
							</li>
						</ol>
					</div>
				</v-flex>
				<v-flex shrink>
					<v-divider />
					<v-textarea
						label="Введите сообщение"
						v-model="message"
						rows="1"
						auto-grow
						:loading="loading"
						:error-messages="error"
						:append-icon="message ? 'icon-chevron_right' : null"
						@click:append="send"
						@keypress.13.prevent="send"
						@focus="onFocus"
					></v-textarea>
				</v-flex>
			</v-layout>
		</v-container>
	</v-card>
</template>
<script>
// todo https://codepen.io/edward1995/pen/gXEPPa
export default {
	data() {
		return {
			loading: false,
			error: null,
			message: null,
			messages: [],
			IDBsupport: true,
		};
	},
	created() {
		let t = this;
		t.sound = new Audio("/sound/dzin.mp3");
		t.$socket.send({ action: "chat_open" });
		if (!('indexedDB' in window)) t.IDBsupport = false;

		t.openDb().then(db => {
			if (!db) return;
			t.dbi = db;
			let transaction = db.transaction("messages", "readonly"),
				objectStore = transaction.objectStore("messages"),
				index = objectStore.index("date"),
				openCursorRequest = index.openCursor(null, "prev"),
				count = 0,
				messages = [],
				max_date = 0;

			openCursorRequest.onsuccess = function(event) {
				count++;
				let cursor = event.target.result;
				if (cursor) {
					if (cursor.value.date > max_date) max_date = cursor.value.date;
					messages.unshift(cursor.value);
					if (count < 20) cursor.continue();
					else t.pushMessages(messages, max_date);
				} else {
					t.pushMessages(messages, max_date);
				}
			};
		});

		t.$socket.bind("message", message => t.showMessage(message, false));
		this.$eventBus.$emit('player-focus', false);
	},
	methods: {
		close() {
			this.$emit("onClose");
			this.$eventBus.$emit('player-focus', true);
		},
		onFocus() {
			this.$eventBus.$emit('player-focus', false);
		},
		send() {
			let t = this;
			t.$socket.send({
				action: "chat_message",
				message: t.message
			});
			t.message = "";
			t.scrollBottom();
		},
		scrollBottom() {
			this.$vuetify.goTo(9999, {
				container: document.querySelector(".chat")
			});
		},
		showMessage(message, init) {
			let t = this, toSave = {};
			Object.assign(toSave, message);
			if (!message.date) {
				const today = new Date();
				toSave.date = today;
				message.date = today.toTimeString().replace(/^(\d{2}:\d{2}).*/, "$1");
				if (message.sender != "self") t.sound.play();
			} else {
				const date = new Date(message.date);
				toSave.date = date;
				message.date = date.toTimeString().replace(/^(\d{2}:\d{2}).*/, "$1");
			}

			t.messages.push(message);

			if (!init && t.IDBsupport && t.dbi) {
				let transaction = t.dbi.transaction("messages", "readwrite"),
					table = transaction.objectStore("messages");
				table.add(toSave);
			}
			t.scrollBottom();
		},
		pushMessages(messages, max_date) {
			let t = this, time = max_date ? parseInt(max_date.getTime() / 1000) : 0;
			messages.forEach(message => {
				t.showMessage(message, true);
			});
			t.$socket.send({ action: "chat_getMessages", time: time });
		},
		openDb() {
			if (!this.IDBsupport) return new Promise(function(resolve) {resolve(false);});

			return new Promise(function(resolve, reject) {
				var req = indexedDB.open("chat", 1);
				req.onsuccess = function() {
					resolve(this.result);
				};
				req.onerror = function(evt) {
					// eslint-disable-next-line
					console.error("openDb:", evt.target.errorCode);
					reject();
					//t.error = evt.target.errorMessage;
				};

				req.onupgradeneeded = function(evt) {
					let thisDB = evt.target.result;
					if (!thisDB.objectStoreNames.contains("messages")) {
						let store = thisDB.createObjectStore("messages", {
							keyPath: "id",
							autoIncrement: true
						});

						store.createIndex("date", "date", { unique: false });
					}
				};
			});
		}
	}
};
</script>

<style scoped>
#chat-box {
	position: fixed;
	right: 0px;
	top: 70px !important;
	width: 370px;
	z-index: 3;
	opacity: 0;
	height: 0px;
	transition: height 0s 0.4s, opacity 0.4s 0s;
	overflow: hidden;
}
#chat-box.visible {
	height: 100%;
	padding-bottom: 70px;
	opacity: 1;
	transition: height 0s 0s, opacity 0.6s 0s;
}
.chat {
	background: none;
	padding: 0 0 10px;
	width: 100%;
	flex: 1;
	overflow: auto;
	color: black;
}
.chat li {
	padding: 0.5rem;
	overflow: hidden;
	display: flex;
	position: relative;
}

.chat-message {
	font-size: 0.9rem;
	overflow: hidden;
	display: flex;
}

.visitor {
	justify-content: flex-end;
	align-items: flex-end;
}

.visitor .msg {
	order: 1;
	border-bottom-right-radius: 0;
	background-color: #aaa;
}

.admin .msg {
	order: 1;
	border-top-left-radius: 0;
	background-color: #eee;
}
.admin:before,
.visitor:after {
	content: "";
	position: absolute;
	left: 0px;
	width: 0px;
	height: 0px;
	border: 5px solid #eee;
	border-left-color: transparent;
	border-bottom-color: transparent;
}
.visitor:after {
	border: 5px solid #aaa;
	border-right-color: transparent;
	border-top-color: transparent;
	left: auto;
	right: 0px;
	box-shadow: 1px 2px 0 #d4d4d4;
}
.msg {
	word-wrap: break-word;
	background: white;
	min-width: 50%;
	max-width: 80%;
	padding: 10px;
	border-radius: 2px;
	box-shadow: 1px 2px 0 #d4d4d4;
}

.msg p {
	margin: 0 0 3.2px 0;
	margin: 0 0 0.2rem 0;
}

.msg .time {
	font-size: 0.7rem;
	color: #777;
	margin-top: 1px;
	float: right;
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
}
.v-application ul,
.v-application ol {
	padding-left: 0px;
}
</style>